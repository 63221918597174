/** @jsx jsx */
import { jsx, Container, Text, Box, Heading } from 'theme-ui'
import { useEffect, useState } from 'react'
import Layout from '~/components/layout'
import { useCartContext } from '~/context/cart-context'
import { CheckoutLineItemInput, CheckoutProductVariant } from '../@types/models'
import { space } from '~/gatsby-plugin-theme-ui'
import { CartItem } from '~/components/cart-item'
import { Button } from '~/components/button'
import CheckoutFlowLayout from '~/components/checkout-flow-layout'
import useCheckoutLink from '~/hooks/use-checkout-link'
import useQuantityNote from '~/hooks/use-quantity-note'
import { formatMoney } from '~/utils/format'
import { lineItemsSummary } from '~/utils/price'
import { useCustomerContext } from '~/context/customer-context'
import { CartMessage } from '~/components/cart-item/cart-message'
import { graphql, useStaticQuery } from 'gatsby'
import { useRemovedItem } from '~/components/cart-item/removedItem'
import { DashboardSflCarousel } from '~/components/account-layout/components/saveForLaterCarousel'
import { AffirmSnippet } from '~/componentsV2/affirm'
import { LoadingText } from '~/componentsV2/loadingText'
import { H2 } from '~/styled/components/typography'

const query = graphql`
  query cartProducts {
    data: allShopifyProduct {
      edges {
        node {
          title
          handle
          totalInventory
          variants {
            title(type: RAW)
            shopifyId
            image {
              originalSrc
              altText
            }
            price {
              amount
            }
          }
        }
      }
    }
  }
`

const CheckoutButton = () => {
  const checkoutLink = useCheckoutLink()
  return (
    <Button
      as="link"
      to={checkoutLink}
      label="Proceed to Checkout"
      sxProps={{
        width: '100%',
        fontSize: ['12px', '16px'],
      }}
    />
  )
}

const CartSummaryPage = () => {
  const { checkout, items, saveItem, isInit, isFetching } = useCartContext()
  const { getNoteBySelectedQuantity } = useQuantityNote({})
  const { customerType } = useCustomerContext()
  const totalPrice = lineItemsSummary(customerType.trade, items)
  const { RemovedItem, onRemove } = useRemovedItem()

  const { data } = useStaticQuery(query)

  useEffect(() => {
    if (typeof window !== 'undefined' && data && isInit) {
      const searchParams = new URLSearchParams(window?.location?.search)
      const productId = searchParams.get('productId')

      const fetchData = async () => {
        const product: CheckoutLineItemInput = {
          variantId: 'gid://shopify/ProductVariant/' + productId,
          quantity: 1,
          customAttributes: [],
        }

        const found = data.edges.find((edge: any) =>
          edge.node.variants.some((variant: any) => variant.shopifyId.includes(productId))
        )
        const variant = found.node.variants.find((variant: any) =>
          variant.shopifyId.includes(productId)
        )
        const message = getNoteBySelectedQuantity(found.totalInventory, 1)

        const imageSrc = variant.image?.originalSrc

        await saveItem(product, found.node.title, {
          ...((variant as unknown) as CheckoutProductVariant),
          image: imageSrc ? { transformedSrc: imageSrc } : variant.image,
        } as CheckoutProductVariant)
      }

      if (productId) {
        fetchData()

        searchParams.delete('productId')
        const newUrl = `${window.location.pathname}`
        window.history.replaceState(null, '', newUrl)
      }
    }
  }, [data, items, isInit])

  if (isFetching) {
    return (
      <Layout headerVariant="default" footer={false}>
        <Container
          sx={{
            minHeight: ['200px', null, '300px'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pt: [51, null, 0],
          }}
        >
          <LoadingText />
        </Container>
      </Layout>
    )
  }

  return (
    <CheckoutFlowLayout
      data-comp="cart-summary-page"
      main={
        <Box
          data-cy="cart-items"
          sx={{
            mt: ['20px', space.l],
            mb: ['30px', null, 0],
          }}
        >
          <RemovedItem />
          {items?.length ? (
            items?.map(item => (
              <CartItem
                variant="primary"
                item={item}
                key={`${item.variant.id}${item.quantity}`}
                onRemove={onRemove}
                source="cart-page"
              />
            ))
          ) : (
            <Box
              sx={{
                p: [40, null, 60],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <H2>Your cart is empty.</H2>
            </Box>
          )}
          <DashboardSflCarousel />
        </Box>
      }
      side={
        <Box>
          <Heading
            variant="title5"
            as="h3"
            sx={{
              mb: ['15px', space.md1],
              pb: ['15px', space.md1],
              borderBottom: '1px solid',
              borderColor: 'border',
              pl: [0, 12, 0],
              pr: [0, 12, 0],
              fontSize: ['22px!important', '33px!important'],
              textTransform: ['uppercase'],
            }}
          >
            Order summary
          </Heading>
          <Box
            sx={{
              pl: [0, 12, 0],
              pr: [0, 12, 0],
            }}
          >
            <table sx={{ width: ['100%'] }}>
              <tbody>
                <tr>
                  <td>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        textTransform: 'uppercase',
                        fontSize: ['12px', '15px;'],
                        color: '#000',
                        fontWeight: 600,
                        mb: ['10px', '15px'],
                        fontFamily:
                          'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                      }}
                    >
                      Subtotal
                      <div>{checkout ? formatMoney(totalPrice) : formatMoney('0')}</div>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: ['10px', '15px'] }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          textTransform: 'uppercase',
                          fontSize: ['12px', '15px;'],
                          color: '#000',
                          fontWeight: 600,
                          fontFamily:
                            'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                        }}
                      >
                        Shipping
                        <div>TBD</div>
                      </Box>
                      <CartMessage message="Complimentary Will Call available in Los Angeles." />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        textTransform: 'uppercase',
                        fontSize: ['12px', '15px;'],
                        color: '#000',
                        fontWeight: 600,
                        mb: ['10px', '15px'],
                        fontFamily:
                          'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                      }}
                    >
                      Tax
                      <div>TBD</div>
                    </Box>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Box
                      sx={{
                        borderTop: '1px solid',
                        borderColor: '#E6E6E6',
                        mb: '25px',
                        mt: ['0', '10px'],
                        pt: ['20px', '25px'],
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          textTransform: 'uppercase',
                          fontSize: ['12px', '15px;'],
                          color: '#000',
                          fontWeight: 600,
                          width: '100%',
                          fontFamily:
                            'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                        }}
                      >
                        Estimated Total{' '}
                        <div>{checkout ? formatMoney(totalPrice) : formatMoney('0')}</div>
                      </Box>
                    </Box>
                  </td>
                </tr>
              </tfoot>
            </table>
            {checkout && <CheckoutButton />}
            {checkout && <AffirmSnippet value={totalPrice} />}
          </Box>
        </Box>
      }
      title="Cart"
      totalItems={items.length}
    />
  )
}

export default CartSummaryPage
